var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.KeySize = exports.KeyType = void 0;
/** All supported key types */
var KeyType;
(function (KeyType) {
  KeyType[KeyType["ED25519"] = 0] = "ED25519";
})(KeyType = exports.KeyType || (exports.KeyType = {}));
var KeySize;
(function (KeySize) {
  KeySize[KeySize["SECRET_KEY"] = 32] = "SECRET_KEY";
})(KeySize = exports.KeySize || (exports.KeySize = {}));
export default exports;